import React from 'react';

import CategoryPage from '../../components/CategoryPage';

const Weeding = () => (
  <CategoryPage
    title="Família"
    totalItems={1}
    s3Path={`portfolio/family/`}
    internalPath={`/familia/`}
    nextPath={`/gravida`}
    nextLabel="Grávida"
    prevPath={`/bebes`}
    prevLabel="Bebé"
  />
)

export default Weeding;
